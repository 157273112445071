/**
 * Code splitting Components
 * AsyncComponents
*/
import React from 'react';
import Loadable from 'react-loadable';
import { HulkPageLoader } from 'components/GlobalComponents';

//  Dashboard 1
const AsyncDashboard1Component = Loadable({
	loader: () => import("views/Dashboard1"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Master user //
const AsyncUserMasterComponent = Loadable({
	loader: () => import("views/Master/UserMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});


//Configuration
const AsyncConfigurationMasterComponent = Loadable({
	loader: () => import("views/Master/ConfigurationMaster"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// User Settings
const AsyncUserSettingsComponent = Loadable({
	loader: () => import("views/UserSettings"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
// Error Page 404
const AsyncErrorPage404Component = Loadable({
	loader: () => import("views/Error/404"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 403 (Unauthorized page)
const AsyncErrorPage403Component = Loadable({
	loader: () => import("views/Error/403"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

// Error Page 500
const AsyncErrorPage500Component = Loadable({
	loader: () => import("views/Error/500"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncSampleFormComponent = Loadable({
	loader: () => import("views/SampleForm"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncFormInformationComponent = Loadable({
	loader: () => import("views/Master/HelpForm"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});
const AsyncGalleryComponent = Loadable({
	loader: () => import("views/Master/GalleryForm"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncGalleryCategoryComponent = Loadable({
	loader: () => import("views/Master/GalleryCategory"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncUpdateForceVersionsComponent = Loadable({
	loader: () => import("views/Master/updateForceVersions"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
});

const AsyncServerSettingsComponent = Loadable({
	loader: () => import("views/Master/ServerSettings"),
	loading: () => <HulkPageLoader />,
		delay: 3000,
	});
	
	const AsyncWhatsNewComponent = Loadable({
		loader: () => import("views/Master/whats-new"),
		loading: () => <HulkPageLoader />,
		delay: 3000,
	});
const AsyncPasswordConfigurationComponent = Loadable({
	loader: () => import("views/Master/PasswordConfiguration"),
	loading: () => <HulkPageLoader />,
	delay: 3000,
})

export {
	AsyncDashboard1Component,
	AsyncUserMasterComponent,
	AsyncConfigurationMasterComponent,
	AsyncUserSettingsComponent,
	AsyncErrorPage404Component,
	AsyncErrorPage500Component,
	AsyncErrorPage403Component,
	AsyncSampleFormComponent,
	AsyncFormInformationComponent,
	AsyncGalleryComponent,
	AsyncGalleryCategoryComponent,
	AsyncUpdateForceVersionsComponent,
	AsyncServerSettingsComponent,
	AsyncWhatsNewComponent,
	AsyncPasswordConfigurationComponent
};