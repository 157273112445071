const MenuItems = [
  {
    menu_title: "Dashboard",
    path: "/app/dashboard",
    icon: "dashboard",
    category: "general",
    isMenuOpen: false,
    menu: "dashboard",
    fullPageMenu: false,
    desc: true,
    content: "",
    child_routes: null,
    permission: true
  },

  {
    menu_title: "Master",
    path: "/app/master/",
    icon: "folder",
    category: "general",
    isMenuOpen: false,
    menu: "master",
    fullPageMenu: false,
    desc: true,
    content: "",
    permission: true,
    child_routes: [
      {
        path: "/app/master/user",
        menu_title: "User Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      },
      {
        path: "/app/master/helpMaster",
        menu_title: "Help Master",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      },
      {
        path: "/app/master/configuration",
        menu_title: "Company",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      },

      {
        path: "/app/master/gallery-category",
        menu_title: "Gallery Category",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      },
      {
        path: "/app/master/gallery",
        menu_title: "Gallery",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      },
      {
        path: "/app/master/updateForceVersions",
        menu_title: "Update Force Versions",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      },
      {
        path: "/app/master/server-settings",
        menu_title: "Server Settings",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      },
      {
        path: "/app/master/password-configuration",
      menu_title: "Password Configuration",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      },
     

      {
        path: "/app/master/whatsNew",
        menu_title: "Whats-New",
        icon: "equalizer",
        fullPageMenu: false,
        isMenuOpen: false,
        third_child_routes: null,
        permission: true
      }
    ],
  },


];

export default MenuItems;
